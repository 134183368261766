/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Spin, Button, Modal} from 'antd';
import {parseUrlParams} from './core/library/url';
import User from './core/user';
import AppStore from './core/library/store';
import * as I18N from './core/library/i18n';
import { AppProvider } from './context/app';
import Config from './config';
import Layout from './layout';
// import AppStore from './core/library/store/index';

// AppStore.set('oauth_access', 'eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDQyNDA3NDYsImlhdCI6MTY0MTY0ODc0Niwic2Vzc2lvbiI6IjYxZDk5MjZhYWNhZGQ1NjMxNDE4Zjk4ZiIsInN1YiI6IjVkNGFkMzBkYzljNjAyZmY2MjM4YmZlYiJ9.DZ2TG6QvLli6RXFD-ZgZtZjRdyIoOu2lQmmVTjVGEn8Jes8MG4vTS3aleow28HjEo8pUPlKSZU4aZIeNVYmuj8o8P3nCG0w59p015Ajxu0HOOAwaKoTXA2vbLd5ZfJHIP8hJBG50kA_9QcJwSV-_nUkh7XlF3o-zWFQnHax8xo9ixQth3NOZRJsdC6o6RIinNylWGqJX4czE1MSfIVXDSJG43rRJCR7xK3FEg6F1Yohk_JdDRTw-yfAb9d-P5S9kHzTzIioyis3NNwuDX51jQ2FDLpdvQdTwRiQVHvVbi0nsH6orbFLrPpU2QPL7L5WGSr-ed8XqgsQaMPT-phEA35Opeguo-NpB-qzF59PQkiZkdeqJB1Bf5jkpIyOlPJJI9300Qj-OhRbOW6aDdk3O6LZzYdpx-i1zaxb7EbsSc_Nc2XrxwEpL3GoPD8dXoFCLlYYuJ1c7TI0MrjB1dag8PkYTIjfYplq4xgCMEP1kgjMZDJ9J5S8XE06g0qqe2bTwk56dIiglVpZY3aSS0bhhNA2PbDLqkHa-YcwKfm7vRU2_dxMZyIe7EgHZ4453SK7m_P-UQtn8QSP14xgPX_zyC_mAZW4DHU7ekR4Ldo8tZhqzvexSMepg-OWCmdvXXKeohPkfCKOLk085gk1uy2jWShMlWjLdbFs3zyz_IdfIWyE');

const TIMEOUT_CHECK_AUTHORIZATION = 300000;

const AppAuthorizationErr = (p) => {
    return (
        <React.Fragment>
            <span>{I18N.t('AUTHORIZATION_ERROR')}</span>
            <Button
                type={`primary`}
                htmlType={`button`}
                onClick={() => p.logout()}
            >
                {I18N.t('LOGIN')}
            </Button>
        </React.Fragment>
    );
};

const AppAuthorizationWait = (p) => {
    return (
        <React.Fragment>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: 300, margin: '0 auto', alignItem: 'center', alignContent: 'center'}}>
                <div style={{textAlign: 'center', marginBottom: 40}}>{I18N.t('AUTHORIZATION_WAIT')}</div>
                <Button
                    type={`primary`}
                    htmlType={`button`}
                    onClick={() => window.location.href = '/'}
                >
                    {I18N.t('LOGIN')}
                </Button>
            </div>
        </React.Fragment>
    );
};



class App extends React.Component {

    static defaultProps = {
        history: {},
    };

    static propTypes = {
        history: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        this.user = new User();
        let url = parseUrlParams();
        this.state = {
            key: url.key || "",
            message: I18N.t('LOADING'),
            loading: true,
            isWait: window.location.href.indexOf('logout') + 1 > 0,
            authModal: false,
            authLoading: false,
            header: {
                title: I18N.t('DASHBOARD_WELCOME'),
                showBack: false,
            },
        };
        this.refreshTimeout = 0;
    }

    updateHeader = (header) => {
        this.setState({ header });
    };

    checkTokens = () => {
        this.user.isAuth().then(auth => {
            this.refreshTimeout = setTimeout(() => {
                this.checkTokens();
            }, TIMEOUT_CHECK_AUTHORIZATION)
        }).catch(e => {
            this.setState({ authModal: true });
        });
    };


    // load user authorization
    userAuthorization = () => {
        this.user.isAuth().then(auth => {
            console.log(`User auth info: `, auth);
            this.setState({
                loading: false,
            }, () => {
                const redirectPath = AppStore.get('redirect_path');
                AppStore.remove(`redirect_path`);
                if (redirectPath) {
                    this.props.history.push(redirectPath);
                }
                

                this.refreshTimeout = setTimeout(() => {
                    this.checkTokens();
                }, TIMEOUT_CHECK_AUTHORIZATION)
            });
        }).catch(e => {
            AppStore.set(`redirect_path`, `${window.location.pathname}${window.location.search}`);
            window.location.href = this.user.generateAuthorizatoinUrl();
        });
    };


    generateTokens = () => {
        this.user.generateUserTokens(this.state.key).then(data => {
            if (data.result) {
                this.props.history.push('/');
                this.userAuthorization();
            } else {
                this.setState({message: <AppAuthorizationErr logout={this.logout}/>});
            }
        }).catch(e => {
            this.setState({message: <AppAuthorizationErr logout={this.logout}/>});
        });
    };

    componentDidMount() {
        if(this.state.isWait === false) {
            if (this.state.key === '') {
                this.userAuthorization();
            } else {
                this.generateTokens();
            }
        }
    };

    resumeAuthorization = () => {
        this.setState({authLoading: true}, () => {
            this.user.updateTokens().then(data => {
                if (data.data && data.data.tokens) {
                    this.user.setTokens(data);
                    this.setState({ authLoading: false, authModal: false });
                } else {
                    this.user.logout();
                    window.location.reload();
                }
            }).catch(e => {
                console.error(`error load tokens: `, e);
                this.user.logout();
                window.location.reload();
            });
        });

    };

    logout = () => {
        this.user.logout();
        window.location.href='?logout';
    };

    render() {
        if (this.state.isWait) return <AppAuthorizationWait />;
        return (
            <Spin
                wrapperClassName={`app${this.state.loading ? ` app_loading`: ''}${Config.APP_CLASS ? ` project_${Config.APP_CLASS}` : ''}`}
                spinning={this.state.loading}
                tip={this.state.message}>
                {this.state.loading === false && (
                    <AppProvider value={{
                        user: this.user,
                        header: this.state.header,
                        actions: {
                            updateHeader: this.updateHeader,
                        }
                    }}>
                        <Modal
                            visible={this.state.authModal}
                            title={I18N.t('AUTHORIZATION_EXPIRED')}
                            footer={!this.state.authLoading ? [
                                <Button type={`danger`} key="back" icon={`logout`} onClick={this.logout}>
                                    {I18N.t('LOGOUT')}
                                </Button>,
                                <Button type={`primary`} key="back" icon={`login`} onClick={this.resumeAuthorization}>
                                    {I18N.t('RESUME')}
                                </Button>,
                            ] : null}
                        >
                            <Spin spinning={this.state.authLoading}>
                                {I18N.t('YOUR_AUTHORIZATION_EXPIRED')}
                            </Spin>
                        </Modal>
                        <Layout layout={Config.PROJECT.LAYOUT} user={this.user}/>
                    </AppProvider>
                )}
            </Spin>
        );
    }
}


export default withRouter(App);
