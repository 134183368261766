export const RU = {
    PODCASTS_LIST: 'Подкасты',
    PODCASTS: 'Подкасты',
    PODCASTS_EDIT: 'Подкасты',
    PCATEGORIES_LIST: 'Категории подкастов',
    PODCAST_CATEGORIES: 'Категории',
    PCATEGORIES_EDIT: 'Категории подкастов',
    PCATEGORIES: 'Категория',
    TYPE_PCATEGORIES: 'Категории подкастов',
    
    LOADING: 'Идет загрузка',
    DASHBOARD: 'Главная',
    CATEGORIES: 'Рубрики',
    TAGS: 'Теги',
    TYPE_TAGS: "Теги",
    TRENDS: 'Темы',
    BANNERS: 'Баннеры',
    WIDGETS: 'Виджеты',
    PAGES: 'Страницы',
    USERS: 'Пользователи',
    DOCUMENTS: 'Документы',
    ARTICLE: 'Статья',
    EPISODE: 'Вечерка ТВ',
    GALLERY: 'Галереи',
    INFOGRAPHIC: 'Инфографика',
    AUTHORS: 'Авторы',
    GROUPS: 'Группы',
    PERMISSIONS: 'Права',
    PROFILE: 'Профиль',
    LOGOUT: 'Выйти',
    NO_EVENTS: 'нет событий для вас',
    WELCOME_MESSAGE: 'Добро пожаловать в админку VM core beta 1.2',
    DASHBOARD_WELCOME: 'Добро пожаловать',
    ARTICLE_LIST: 'Список статей',
    EPISODE_LIST: 'Список вечерки тв',
    GALLERY_LIST: 'Список галерей',
    INFOGRAPHIC_LIST: 'Список инфографики',
    LOADING_DOCUMENTS: 'Подождите, идет загрузка',
    EDIT: 'редактировать документ',
    VIEW: 'предпросмотр',
    YANDEX_DZEN_TITLE: 'Заголовок для DZEN',
    DELETE: 'удалить документ',
    CROSSLINKS: 'материалы по теме',
    SHOW_ALL_TAGS: 'Показать все теги',
    HIDE_ALL_TAGS: 'Скрыть все теги',
    IMAGE: 'Изображение',
    COVER: 'Обложка',
    VIDEO: 'Видео',
    EXTERNALVIDEO: 'Внешнее видео',
    CODE: 'Код',
    CATEGORY: "Рубрика",
    AUTHOR: 'Автор',
    PUBLISHED: 'опубликован',
    UNPUBLISHED: 'не опубликован',
    PREPUBLISHED: 'готов к публикации',
    CREATE: 'Создать',
    TITLE_OR_URL: 'заголовок или ссылка',
    SELECT_STATUS: 'выберите статус',
    SELECT_TAGS: 'выберите теги',
    SELECT_CATEGORY: 'выберите рубрику',
    DATE_FROM: 'дата начала',
    DATE_TO: 'дата конца',
    ALL: 'все',
    EMPTY_DOCUMENTS: 'нет документов',
    CLEAR_FILTER: 'Очистить фильтр',
    CREATED: 'Создан',
    UPDATED: 'Обновлен',
    AT: 'в',
    SURE_DELETE: 'Вы уверены, что хотите удалить документ?',
    CLOSE: 'Закрыть',
    NOT_SET: '[не заполнено]',
    ARTICLE_ADD: 'Добавить статью',
    UPLOAD_TEXT: 'Кликните по области или перетащите файл',
    COVER_LABEL: 'Обложка',
    UPLOAD_NEW: 'Новое изображение',
    UPLOAD_SEL: 'Архив',
    NEWS: 'Новости',
    ARTICLES: 'Статьи',
    TITLE: 'Заголовок',
    UPDATE_DOCUMENT: 'Сохранить',
    DOC_STATUS: 'Статус',
    CREATE_BY: 'Создан',
    UPDATED_BY: 'Обновлен',
    HISTORY: 'История',
    SUMMARY: 'Лид',
    DELETE_MEDIA: 'Удалить обложку',
    TEXT: 'Текст документа',
    SLUG: 'Слаг (в ссылке)',
    PUBLISHED_AT: 'Опубликовать',
    COORDS: 'Координаты',
    COORDS_HELP: 'Введите радианы места',
    HIDE_MAP: 'Скрыть карту',
    SHOW_MAP: 'Показать карту',
    GENERATE: 'Сгенерировать',
    PUBLISH_IN: 'Опубликовать в',
    SUB_CATEGORY: 'Включен в рубрики',
    VIEW_FIELD: 'Представление',
    SEO_TITLE: 'Seo title',
    SEO_DESCRIPTION: 'Seo description',
    REDIRECT_TO: 'Редирект',
    SEO_AND_VIEW: 'Настройки отображения',
    published: 'опубликован',
    DOCUMENT_HISTORY_EVENT: 'История редактирования документа',
    RECOVER: 'Восстановить',
    TITLE_ERROR_FILL: 'Заполните заголовок документа',
    SUMMARY_ERROR_FILL: 'Заполните лид документа',
    SLUG_PLACEHOLDER: 'Заполните слаг документа',
    SLUG_ERROR: 'Заполните слаг документа',
    SELECT_CATEGORIES: 'Выберите рубрику документа',
    SELECT_AUTHORS_ERROR: 'Выберите авторов документа',
    AUTHORS_PLACEHOLDER: 'Выберите авторов документа',
    TITLE_PLACEHOLDER: 'Введите заголовок',
    SUMMARY_PLACEHOLDER: 'Введите лид',
    SELECT_SUB_CATEGORIES: 'Выберите подрубрику',
    SELECT_TRENDS: 'Выберите темы',
    SELECT_CROSSLINKS: 'Выберите материалы по теме',
    NO_HISTORY: 'Нет истории',
    ARTICLE_EDIT: 'Редактирование статьи',
    EPISODE_ADD: 'Добавление эпизода',
    EPISODE_EDIT: 'Редактирование эпизода',
    COORDS_MAP_HELP: 'Кликнете по карте для отметки места',
    GALLERY_EDIT: 'Редактирование галереии',
    CREATE_BTN: 'Создать',
    PUBLIC_ID: 'ID',
    CREATED_AT: 'Создан',
    UPDATED_AT: 'Обновлен',
    ACTIONS: 'Действия',
    CREATE_DOCUMENT: 'Создать',
    DOCUMENT_UPDATE_ERROR: 'Ошибка обновления документа',
    DOCUMENT_UPDATE_SUCCESS: 'Документ успешно обновлен',
    DOCUMENT_DELETED: 'Документ успешно удален',
    DOCUMENT_DELETE_ERROR: 'Ошибка удаления документа',
    DOCUMENTS_LOAD_ERROR: 'Ошибка загрузки документа',
    REPEAT_AGAIN_LATER: 'Повторите пожалуйста позднее',
    DOCUMENT_UPDATED: 'Документ успешно обновлен',
    LOGIN: 'Войти',
    AUTHORIZATION_WAIT: 'Подождите, идет авторизация',
    AUTHORIZATION_ERROR: 'Ошибка авторизации',
    CLEAR: 'Очистить',
    APPLY: 'Применить',
    SEARCH_MEDIA_PLACEHOLDER: 'Заголовок или копирайт',
    REMOVE: 'Удалить',
    ADD: 'Добавить',
    SOURCE: 'Источник',

    REMOVE_ALL: 'Удалить все',
    SAVE_ALL: 'Сохранить все',
    UPLOAD_FIRST_ROW: 'Кликните или перетащите файл для загрузки',
    UPLOAD_NEXT_ROW: ' ',
    MEDIA: 'Медиа',
    UPLOAD: 'Загрузить',
    SEARCH: 'Поиск',
    MEDIA_LIST: 'Список медиа',
    WIDGET_ADD_TITLE: 'Добавление виджета',
    EDIT_WIDGET: 'Редактирование виджета',
    VIEW_WIDGET: 'Просмотр виджета',
    WIDGET_UPDATED: 'Виджет обновлен',
    WIDGET_CREATED: 'Виджет создан',
    MAX_SIZE: 'Количество документов',
    DOCS_SIZE: 'Количество документов',
    DOC_TYPES: 'Типы документов',
    ADMIN_TITLE: 'Заголовок (админка)',
    WIDGETS_LIST: 'Список виджетов',
    SAVE: 'Сохранить',
    WIDGET_NOT_FOUND: 'Виджет не найден',
    MAXIMUM_ELEMENT_IN_WIDGET: 'Количество элементов в виджете',
    WIDGETS_EDIT: 'Редактирование виджета',
    TAG_LIST: 'Список тегов',
    CATEGORY_LIST: 'Список рубрик',
    AUTHORS_LIST: 'Список авторов',
    PAGES_LIST: 'Список страниц',
    HREF: 'Ссылка',
    TEXT_PLACEHOLDER: 'Текст документа',
    REDIRECT_TO_ERROR: 'Ошибка заполнения редиректа',
    SLUG_FILL_ERROR: 'Ошибка заполнения документа',
    PAGE_LIST: 'Список страниц',
    BANNER_LIST: 'Список баннеров',
    TREND_LIST: 'Список тем',
    TREND_ADD: 'Новая тема',
    TREND_EDIT: 'Редактирование темы',
    BANNER_ADD: 'Добавление баннера',
    BANNER_EDIT: 'Редактирование баннера',
    OTHER: 'Прочее',
    PREVIEW: 'Посмотреть',
    WIDGET_TITLE: 'Заголовок виджета',
    ADMIN_WIDGET_TITLE: 'Заголовок виджета (админка)',
    AUTHOR_LIST: 'Список авторов',
    DOCUMENT_CREATE_FINISH: 'Документ успешно создан',
    AUTHOR_EDIT: 'Редактирование автора',
    AUTHOR_ADD: 'Создание автора',
    LOADING_DOCUMENT: 'Загрузка документа',
    SELECT: 'выбрать',
    VISIBLE: 'Виден на сайте',
    SHOW: 'Рубрики ТВ',
    SHOW_LIST: 'Список рубрик вечерки',
    SHOW_ADD: 'Создать рубрику',
    SHOW_EDIT: 'Редактирование рубрики',
    MEANING: 'Колумнисты',
    MEANING_LIST: 'Колонки',
    MEANING_ADD: 'Добавление колонки',
    MEANING_EDIT: 'Редактирование колонки',
    SUBTITLE: 'Подзаголовок',
    NEWS_LIST: 'Новости',
    NEWS_ADD: 'Создание новости',
    NEWS_EDIT: 'Редактирование новости',
    DRAFT: 'Черновик',
    CHECK_CORRECTOR: 'Проверка корректором',
    CHECK_PLAGIARISM: 'Антиплагиат',
    CHECK_COORDS: 'Заполнены координаты',
    NEED_PHOTO: 'Установлено фото',
    NEED_VIDEO: 'Установлено видео',
    N_NEED_PHOTO: 'Фото',
    N_NEED_VIDEO: 'Видео',
    WORKFLOW: 'Рабочий процесс',
    NEED_RAYTER: 'Сдано журналистом',
    NEED_EDITOR: 'Проверено редактором',
    NEED_CORRECTOR: 'Проверено корректором',
    ERROR_SET_LOCK: 'Ошибка установки блокировки',
    SUCCESS_SET_LOCK: 'Блокировка успешно установлена',
    IN_MATERIAL: 'В материале',
    DOCUMENT_LOCK: 'Документ заблокирован',
    REMOVE_LOCK: 'Удалить блокировку',
    LOCK_REMOVE: 'Блокировка удалена',
    LOCK_REMOVE_ERROR: 'Ошибка удаления блокировки',
    ERROR_CREATE_DOCUMENT: 'Ошибка создания документа',
    DOCUMENT_CREATED: 'Документ успешно создан',
    DOCUMENT_NOT_FOUND: 'Документ не найден',
    STASH_CHANGES: 'У вас есть не сохраненные изменения',
    DOCUMENT_LAST_EDIT_USER: 'Последнее обновление документа',
    DOCUMENT_LAST_EDIT_STASH: 'Последнее автосохранение',
    DELETE_STASH: 'Удалить автосохранение',
    USER_BY: 'пользователем',
    STATUS: 'Статус',
    PUBLISH_CHECK: "Опубликован, проверить",
    PUBLISH: "Опубликован",
    ERROR_LOAD_WORKFLOW: 'Ошибка загрузки документов',
    STATUS_NOT_SET: 'не установлен',
    IMAGE_FIELD: 'Картинка',
    VIDEO_FIELD: 'Видео',
    RAITER_FIELD: 'Корреспондент',
    EDITOR_FIELD: 'Редактор',
    CHECK_FIELD: 'Корректор',
    VIEWS_AND_COMMENTS: 'Кол-во просмотров/кол-во комментов',
    LAST_UPDATED_WORKFLOW: 'Последнее изменение',
    DATE_PUBLISH: 'Дата публикации',
    WORKFLOW_STATS: 'Статус',
    COVER_WORKFLOW: 'Фото',
    VIEWS_COUNT: 'Количество просмотров',
    COMMENTS_COUNT: 'Количество комментариев',
    LOCK_BY: 'Заблокирован',
    READY: 'Готов',
    ARCHIVE: 'Запас',
    PUBLISH_AT_MAIN: 'Главная страница',
    PUBLISH_AT_SMI2: 'СМИ2',
    PUBLISH_AT_DZEN: 'Дзен',
    PUBLISH_AT_NEWSPAPER: 'Опубликован в газете',
    TYPE_ARTICLE: 'Статья',
    TYPE_NEWS: 'Новость',
    TYPE_EPISODE: 'Вечерка ТВ',
    TYPE_INFOGRAPHIC: 'Инфографика',
    TYPE_TREND: 'Темы',
    TYPE_BANNER: 'Баннеры',
    TYPE_MEANING: "Колонки",
    ARCHIVE_LIST: 'Запас',
    TYPE: 'Тип',
    CREATED_BY: 'Пользователь',
    ERROR_LOAD_ARCHIVE: 'Ошибка загрузки запаса',
    SEARCH_PLACEHOLDER: 'Название или slug',
    EDITOR_INSERT_MEDIA: 'Медиа',
    INSERT_PLUGIN: 'Вставка элементов в текст',
    INSERT_IMAGE: 'Изображение',
    INSERT_VIDEO: 'Видео',
    INSERT_INFOGRAPHIC: 'Инфографика',
    ERROR_LOAD_MEDIA: 'Ошибка загрузки media',
    INSERT: 'Вставить',
    MEDIA_TITLE: 'Заголовок',
    FILL_MEDIA_TITLE: 'Введите описание',
    MEDIA_SOURCE: 'Источник',
    MEDIA_COPYRIGHT: 'Копирайт',
    MEDIA_COPYRIGHT_URL: 'Ссылка на источник',
    MEDIA_DELETE: 'Удалить',
    MEDIA_UPLOAD: 'Загрузить',
    EDIT_MEDIA: 'Редактирование медиа',
    SELECT_MEDIA: 'Выбрать из архива',
    MEDIA_SOURCES: 'Источники',
    ERROR_UPDATE_MEDIA: 'Ошибка обновления',
    INSERT_EXTERNALVIDEO: 'Внешнее видео',
    INCORRECT_SOURCE: 'Не известный источник',
    INSERT_CODE: 'Код',
    WIDTH: 'Ширина',
    HEIGHT: 'Высота',
    EMBEDDED: 'Код',
    EMBEDDED_EMPTY: 'Код не может быть пустым',
    ERROR_CREATE_CODE: 'Ошибка создания кода',
    VIDEOS: 'Видео',
    EVENTS: 'События',
    SERVICES: 'Услуги',
    PLACES: 'Места',
    FILMS: 'Фильмы',
    BOOKS: 'Книги',
    MUSIC: 'Музыка',
    ROUTES: 'Маршруты',
    PUBLISH_AT_RSS: 'Дзен.Новости (rss)',
    EVENTS_TEXT: 'Описание события',
    EVENTS_PROPS: 'Свойства события',
    SELECT_DATE: 'Выберите дату',
    DATE_FROM_EVENTS: 'Дата начала',
    DATE_TO_EVENTS: 'Дата окончания',
    BUTTON_NAME: 'Надпись на кнопке',
    BUTTON_LINK: 'Ссылка на кнопке',
    CONTACTS_TEL: 'Контактный телефон',
    CONTACTS_EMAIL: 'Контактный email',
    CONTACTS_WEBSITE: 'Вебсайт',
    SERVICES_PROPS: 'Свойства услуги',
    PLACES_PROPS: 'Свойства места',
    PROPS: 'Свойства',
    LINK: 'Ссылка',
    TYPE_VIDEOS: 'Видео',
    TYPE_EVENTS: 'События',
    TYPE_SERVICES: 'Услуги',
    TYPE_PLACES: 'Места',
    TYPE_FILMS: 'Фильмы',
    TYPE_BOOKS: 'Книги',
    TYPE_MUSIC: 'Музыка',
    TYPE_ROUTES: 'Маршруты',
    EDIT_SHORT: 'Редактировать',
    MEDIA_MAX_WIDTH: 'Максимальная ширина',
    SELECT_WORKFLOW: 'выберите статус',
    ARCHIVE_NEWSPAPER: 'Архив газеты',
    NEWSPAPER: 'Газета',
    NEWSPAPER_EDIT: 'Редактирование газеты',
    NEWSPAPER_NUMBER: 'Номер выпуска',
    NEWSPAPER_NUMBER_PLACEHOLDER: 'Заполните номер выпуска',
    NEWSPAPER_NUMBER_PLACEHOLDER_FILL: 'Ошибка заполнения номера',
    NEWSPAPER_PUBLISHED: 'Дата выпуска',
    NEWSPAPER_TYPE: 'Типы выпуска',
    SELECT_NEWSPAPER_TYPE: 'Выберите тип выпуска',
    PDF_LABEL: 'Выберите pdf',
    DELETE_PDF: "Удалить pdf",
    NEWSPAPER_LIST: 'Список газет',
    REGION_NEWSPAPER: 'Газеты района',
    NEWSPAPER_REGION: 'Район газеты',
    RNEWSPAPER_LIST: 'Газеты района',
    RNEWSPAPER_EDIT: 'Редактирование',
    VIDEO_LABEL: 'Видео',
    SELECT_SHOW: 'Выберите категорию',
    DIRECTORY: 'Справочники',
    MATERIAL_AUTHORS: 'Авторы',
    BOOKS_AUTHORS: 'Авторы книг',
    LIST_BOOK: 'Список',
    BOOKS_GENRE: 'Жанры',
    BOOK_AUTHORS_LIST: 'Список авторов книг',
    BOOK_AUTHORS_EDIT: 'Редактирование автора книг',
    AUTHOR_COVER: 'Фотография',
    AUTHOR_NAME: 'Имя автора',
    BOOK_GENRE_LIST: 'Жанры для книг',
    BOOK_GENRE_EDIT: 'Редактирование жанра',
    BOOK_TEXT: 'Описание книги',
    BOOK_AUTHOR: 'Авторы книги',
    GENRE: 'Жанры',
    SELECT_GENRE: 'Выберите жанры',
    TYPE_BOOK_GENRE: 'Жанры книг',
    TYPE_FILMS_GENRE: 'Жанры фильмов',
    TYPE_MUSIC_GENRE: 'Жанры музыки',
    AUTHOR_QUOTE: 'Цитата автора о книгие',
    AUTHOR_QUOTE_PLACEHOLDER: 'Введите циту автора о его книге',
    BOOK_AUTHOR_COMMENT: 'Автор комментария',
    BOOK_AUTHOR_COMMENT_SELECT: 'Выберите автора комментария',
    AUTHOR_COMMENT_SUBTITLE: 'Подпись автора',
    BOOK_OTHER_MATERIAL: 'Материалы по теме',
    BOOK_OTHER_MATERIAL_SELECT: 'Выберите метариалы по теме',
    BOOK_MORE_TITLE: 'Заголовок на блоке подробнее',
    READ_MORE_BOOKS: 'Читайте также',
    SELECT_READ_MORE_BOOKS: 'Выберите книги',
    FILMS_LIST: 'Список',
    FILMS_AUTHORS: 'Режиссеры',
    FILMS_AUTHORS_LIST: 'Режиссеры',
    FILMS_GENRE: 'Жанры',
    FILMS_GENRE_LIST: 'Список жанров фильмов',
    FILMS_GENRE_EDIT: 'Редактирование жанра',
    FILMS_AUTHOR: 'Режиссеры',
    FILMS_AUTHOR_COMMENT: 'Автор комментария',
    FILMS_AUTHOR_COMMENT_SELECT: 'Выберите режиссера',
    FILM_TEXT: 'Описание фильма',
    VIEW_MORE: 'Смотрите также',

    MUSIC_LIST: 'Список',
    MUSIC_AUTHORS: 'Композиторы',
    MUSIC_AUTHORS_LIST: 'Композиторы',
    MUSIC_GENRE: 'Жанры',
    MUSIC_GENRE_LIST: 'Список жанров музыки',
    MUSIC_GENRE_EDIT: 'Редактирование жанра',
    MUSIC_AUTHOR: 'Композиторы',
    MUSIC_AUTHOR_COMMENT: 'Автор комментария',
    MUSIC_AUTHOR_COMMENT_SELECT: 'Выберите композитора',
    MUSIC_TEXT: 'Описание',
    MUSIC_EDIT: 'Редактирование',

    AUTHORIZATION_EXPIRED: 'Восстановление авторизации',
    YOUR_AUTHORIZATION_EXPIRED: 'Ваша авторизация истекла. Хотите продолжить?',
    RESUME: 'Продолжить',
    CURRENT_VERSION: 'Текущая версия',
    PREVIEW_VERSION: 'Предыдущая версия',
    YOU_ARE_USER_RECOVERY: 'Вы уверены, что хотите восстановить?',

    COMMENTS: 'Комментарии',
    PROFILES: 'Профили',
    PROFILES_LIST: 'Список',
    PROFILES_GROUP: 'Группы',
    PROFILES_PERMISSIONS: "Права",
    COMMENTS_LIST: 'Список комментариев',

    ERROR_LOAD_PROFILES: 'Ошибка загрузки профилей',
    PROFILE_LOGIN: 'Логин',
    PROFILE_CREATED: 'Дата регистрации',
    PROFILE_AVATAR: 'Аватар',
    PROFILE_EMAIL: 'ID',
    PROFILE_NAME: 'Имя',
    PROFILE_ACTIVE: 'Активен',
    PROFILE_APPROVED: 'Подтвержден',
    PROFILE_TYPE: 'Тип',
    EDIT_PROFILE: 'Редактировать профиль',
    PROFILE_SEARCH_PLACEHOLDER: 'Логин/email/имя/фамилию профиля',

    ERROR_LOAD_GROUPS: 'Ошибка загрузки групп',
    GROUPS_SEARCH_PLACEHOLDER: 'Название/код группы',
    GROUPS_LIST: 'Список групп пользователей',
    CREATE_GROUP: 'Создать группу',

    PERMISSIONS_LIST: 'Список прав',
    CREATE_PERMISSION: 'Создать',
    PERMISSIONS_PLACEHOLDER: 'Код/название/описание права',
    ERROR_LOAD_PERMISSIONS: 'Ошибка загрузки прав',
    PERMISSION_CREATED: 'Право успешно создано',
    ERROR_CREATE_PERMISSION: 'Ошибка создания права. Право с этим кодом уже существует',
    PERMISSION_UPDATED: 'Право успешно обновлено',
    ERROR_UPDATE_PERMISSION: 'Ошибка обновления права',
    PERMISSION_CODE: 'Служебный идентификатор',
    PERMISSION_CODE_FILL: 'Заполните служебный идентификатор',
    PERMISSION_TITLE: 'Название',
    PERMISSION_TITLE_FILL: 'Заполните название права',
    PERMISSION_DESCRIPTION: 'Описание',
    DELETE_PERMISSION: 'Удалить право',
    EDIT_PERMISSION: 'Редактировать',
    DELETE_PERMISSION_REQUEST: 'Вы уверены, что хотите удалить право?',
    ERROR_DELETE_PERMISSION: 'Ошибка удаления права',
    SUCCESS_DELETE_PERMISSION: 'Право успешно удалено',
    UPDATE: 'Обновить',
    DESCRIPTION: 'Описание',
    GROUP_TITLE: 'Название группы',
    GROUP_PERMISSIONS: 'Права группы',
    GROUP_UPDATE_FINISH: 'Группа успешно обновлена',
    GROUP_UPDATE_ERROR: 'Ошибка обновления группы',
    GROUP_CREATE_FINISH: 'Группа успешно создана',
    GROUP_CREATE_ERROR: 'Ошибка создания группы',
    GROUP_DELETE_FINISH: 'Группа успешно удалена',
    GROUP_DELETE_ERROR: 'Ошибка удаления группы',
    DELETE_GROUP_REQUEST: 'Вы уверены, что хотите удалить группу?',
    DELETE_GROUP: 'удалить группу',
    EDIT_GROUP: 'редактировать группу',
    ACTIVE: 'Активна',
    PERMISSION: 'Права',
    GROUP_TITLE_FILL: 'Заполните название группы',
    UPDATE_GROUP: 'Обновление группы',
    PROFILE_EDIT: 'Редактирование профиля',
    ERROR_UPDATE_PROFILES: 'Ошибка обновления профиля, попробуйте пожалуйста позднее',
    PROFILES_UPDATED: `Профиль успешно обновлен`,
    ERROR_LOAD_PROFILE: 'Профиль не найден',
    PROFILE_BIRTHDAY: 'Дата рождения',
    PROFILE_ABOUT: 'Информация',
    PROFILE_ADDRESS: 'Район',
    PROFILE_LAST_NAME: 'Фамилия',
    PROFILE_PASSWORD: `Пароль`,
    PROFILE_GROUPS: 'Группы',
    PROFILE_INFO: 'Информация о профиле',
    PROFILE_SUBSCRIBES: 'Подписки профиля',
    THEATER: 'Театр',
    LIST_THEATER: 'Список',
    THEATER_TYPE: 'Тип',
    THEATER_TYPE_LIST: 'Типы выступлений',
    THEATER_LIST: 'Театр',
    SELECT_THEATER_TYPE: 'Выберите тип',
    THEATER_OTHER_MATERIAL: 'Материалы по теме',
    THEATER_MORE_TITLE: 'Заголовок на блоке cмотрите также',
    VIEW_OTHER_THEATER: 'Смотрите также',
    THEATER_OTHER_MATERIAL_SELECT: 'Выберите материалы',
    SELECT_VIEW_OTHER_THEATER: 'Выберите представления',
    TYPE_THEATER: 'Театр',
    TYPE_THEATER_TYPE: 'Типы театра',
    REPORTS: 'Отчеты',
    YANDEX: 'Яндекс',
    MATERIAL_STATS: 'Материалы',
    WIDGETS_STATS: 'Виджеты',
    BUDGET: 'Авторы',
    YANDEX_REPORT: 'Позиции в yandex',
    MATERIAL: 'Материалы',
    MATERIAL_REPORT: 'Отчет по материалам',
    OPINION_BATTLE: 'Битва мнений',
    BATTLE_LIST: 'Битва мнений',
    READ_TIME: "Время на чтение",
    OPINIONS: "Мнения",
    SELECT_OPINIONS: "Выберите мнения",
    WIDGETS_REPORT: "Отчет по виджетам",
    LOAD_REPORT: "Идет загрузка отчета",
    ERROR_LOAD_REPORT: "Ошибка загрузки отчета",
    VIEW_DOCS: 'Посмотреть документы',
    REPORT_TOTAL: 'Всего',
    REPORT_WEEK: '7 дней',
    REPORT_DAY: '24 часа',
    REPORT_HOUR: '1 час',
    PUBLISH_AT_GOOGLE: "Google",
    PUBLISH_AT_PULSE: 'Mail Pulse',
    PUBLISH_AT_MIRROR: 'Опубликовать в yandex зеркало',
    ONLINE: 'Прямая трансляция',
    SCHEDULE: 'Расписание',
    SCHEDULE_LIST: 'Расписание',
    SCHEDULE_EDIT: 'Редактирование',
    DATE_START: 'Дата начала',
    DATE_END: 'Дата окончания',
    DATE: 'Дата',
    SORT: 'Сортировка',
    DISPLAY_TITLE: 'Отображаемый заголовок',
    EDIT_WIDGET_ITEM: 'Редактирование заголовка',
    DISPLAY_NAME: 'Отображаемое имя',
    CHAT: 'Чат',
    DELETE_CHAT_MESSAGE: 'Удалить сообщение',
    RESTORE_CHAT_MESSAGE: 'Восстановить сообщение',
    USER_BLOCKS: 'Блокировки пользователя',
    USER_BANNED_TO: 'Пользователь заблокирован на',
    SECONDS: 'Секунд',
    PUBLISH_AT_NEWSLINE: 'Лента новостей',
    LIST_EVENTS: 'Список',
    EVENTS_TYPES: 'Типы событий',
    ETYPE_LIST: 'Типы событий',
    EVENTS_CITY: 'Города',
    EVENT_TYPE: 'Тип события',
    SELECT_EVENT_TYPE: 'Выберите тип события',
    EVENT_CITY: 'Город события',
    SELECT_EVENT_CITY: 'Выберите город события',
    WIDGETS_MAINPAGE: 'Главная страница',
    DOWNLOAD_XLS: 'Скачать XLS',
    EXPORT_TO: 'Экспортировать',
    MOVE_TYPE: 'Тип материала',
    ERROR_MOVE_TYPE: 'Ошибка обновления типа документа',
    TYPE_UPDATED: 'Документ успешно перенесен',
    REDIRECT_MESSAGE: 'Вы будете переадресованы через несколько секунд',
    ERROR_LOAD_SEARCH: 'Ошибка загрузки, повторите пожалуйста позднее',
    YES: 'Да',
    NO: 'Нет',
    NOT_PUBLISHED: 'Не опубликован',
    TYPE_SHOW: 'Рубрики вечерки',


    SENDING: 'Идет отправка',
    CANCEL: 'Отменена',
    SEND: 'Отправлена',
    PREPARE: 'Ожидает отправки',
    CREATE_EMAIL: 'Создание новой рассылки',
    EMAIL_RECOMMEND: 'Рекомендовать для рассылки',

    AUTHORS_RATES: 'Коэффиценты',
    AUTHORS_RATE: 'Коэффицент',
    ARATES_LIST: 'Коэффиценты',
    AUTHORS_VIEWS: 'Гонорары',
    AVIEWS_LIST: 'Гонорары',
    RATE: 'Ставка',
    ARATES_EDIT: 'Коэффиценты',
    VIEWS_FROM: 'Просмотры от',
    VIEWS_TO: 'Просмотры до',
    FEE: 'Гонорар',
    VIDEO_REPORT: 'Отчет по видео',
    EMBARGO: 'Эмбарго',
    PUBLISH_AT_WIFI: 'Опубликовать в wifi',
    WIDGETS_EDITOR: 'Редакторские',
    WIDGETS_ADS: 'Рекламные',
    MAIN_MATERIAL: 'Главный материал',
    SELECT_MAIN_MATERIAL: 'Выберите главный материал',

    UNPUBLISH_ACTION: 'Распубликовать',
    PUBLISH_ACTION: 'Опубликовать',
    PUBLISH_POPCONFIRM: 'Вы уверены, что хотите опубликовать этот материал?',
    UNPUBLISH_POPCONFIRM: 'Вы уверены, что хотите распубликовать этот материал?',

    PUSH: 'Push рассылка',
    SEND_PUSH: 'Отправить push',
    PUSH_SEND: 'Push рассылка успешно отправлена',
    PUSH_SEND_ERROR: 'Ошибка отправки рассылки',
    POPULAR_MATERIAL: 'Знак качества',
    MAINPAGE_PUBLISH: 'Публикация на главной странице',
    MAINPAGE_FIELD: 'Главная',
    HIDE_GOOGLE_ADS: 'Скрыть рекламу',
    NO_INDEX: 'Не индексировать',
    FRENCH_QUOTES: "«»",
    LONG_DASH: "тире —",
    MIDDLE_DASH: "тире –",
    INFOGRAPHIC_MATERIAL: 'Материал',
    SELECT_MATERIAL: 'Выберите материал',
    NOTIFICATIONS: 'Уведомления',
    NOTIFICATION_IN: 'Материал набрал просмотров',
    DOWNLOAD: 'Скачать',
    VIDEO_VIEWS: 'Видео полн.',
    CHAT_MESSAGES: 'Список сообщений в чате',
    COPYRIGHT: 'Копирайт',
    COPYRIGHT_PLACEHOLDER: 'автор/издание',
    COPYRIGHT_URL: 'Ссылка',
    COPYRIGHT_URL_PLACEHOLDER: 'Ссылка на источник',
    MEDIA_DESCRIPTION: 'Описание',
    MEDIA_KEYWORDS: 'Ключевые слова',

    POLLS: 'Опросы',
    POLLS_LIST: 'Список опросов',
    POLLS_EDIT: 'Редактирование опроса',
    TOTAL_VOTES: 'Количество голосов',
    TYPE_POLLS: 'Опросы',
    POLL_FINISH: 'Опрос окончен',

    SETTINGS: 'Настройки',
    HIDE_CHAT: 'Скрыть чат',

    SELECT_POLLS: 'Выберите опрос',
    STREAM_STATS: 'Ст. вещания',
    ERROR_LOAD_REPORT_STREAM: 'Ошибка загрузки отчета, попробуйте пожалуйста позднее',
    POLL_HIDE_RESULT: 'Скрыть всего проголосовавших',
    ADD_ADDRESS: 'Добавить адрес',

    ANTIPLAGIAT: 'Антиплагиат',
    AN_ORIGINAL: 'Уникальность',
    AN_PLAGIAT: 'Плагиат',
    AN_WORKFLOW_ORIGINAL: 'Антиплагиат уникальность',
    DOWNLOAD_DEVELOPER_LOG: 'Developer log',
    MAP_CATEGORY: 'Кат. карты',
    MCAT_LIST: 'Категории карты',
    COORDS_GROUP: 'Гео',
    MCAT_EDIT: 'Категории редактирование',
    MCAT_FIELD: 'Категория',
    SELECT_MCAT: 'Выберите категорию',
    ANONS_DATE: 'Отображать до',
    COLOR: 'Цвет',
    COLOR_PLACEHOLDER: 'Введите цвет',
    IS_ANONS: 'Анонс',
    SPECIAL_PROJECTS: 'Спецпроекты',
    SPECIAL_PROJECT: 'Спецпроект',
    COVID19: 'Коронавирус',
    MOSCOW_RECOVERED_TOTAL: 'Выздоровели',
    MOSCOW_RECOVERED_DAY: 'Выздоровели за сутки',
    MOSCOW_INFECTED_TOTAL: 'Заражено',
    MOSCOW_INFECTED_DAY: 'Заражено за сутки',
    MOSCOW_DEAD_TOTAL: 'Умерло',
    MOSCOW_DEAD_DAY: 'Умерло за сутки',
    COVID_PLACEHOLDER: 'Введите значение',
    COVID_HREF: 'Ссылка с виджета',
    COVID_TITLE: 'Заголовок',
    MEDIA_HAS_BEEN_UPDATED: 'Элемент успешно обновлен',
    MEDIA_HIDE_CONFIRM: 'Если скрыть элемент, он пропадет из поиска но будет доступен в документе. Вы уверены?',
    HIDE: 'Скрыть',
    ALL_NOTIFICATIONS: 'Все уведомления',
    DOCUMENT: 'Документ',
    NOTIFICATION_HIDE_ERROR: 'Ошибка удаления уведомления',
    DELETE_ALL: 'Удалить все',
    FILE_HAS_BEEN_UPLOADED: `Файл загружен`,

    /*media gallery*/
    MEDIA_GALLERY_LIST: `Фотоархив`,
    MEDIA_GALLERY_LIST_LOAD_ERROR: `Ошибка загрузки данных`,
    MEDIAGALLERY_LOADING_ERROR_MESSAGE: `Во время загрузки произошла ошибка`,
    MEDIAGALLERY_LOADING_ERROR_DESCRIPTION: `Повторите попытку позже или свяжитесь с разработчиками`,
    MEDIAGALLERY_RELOAD: `Повторить попытку`,
    MEDIAGALLERY_VIEW_CARD: 'Сетка',
    MEDIAGALLERY_VIEW_LIST: 'Список',
    MEDIAGALLERY_OBJECT: `Объектов`,
    MEDIAGALLERY_TYPE_IMAGE: 'Изображение',
    MEDIAGALLERY_TYPE_COVER: 'Изображение',
    MEDIAGALLERY_TYPE_VIDEO: 'Видео',
    MEDIAGALLERY_EDIT_IMAGE: `Редактировать изображение`,
    MEDIAGALLERY_DOWNLOAD_IMAGE: `Скачать оригинал`,
    MEDIAGALLERY_DELETE_IMAGE: `Удалить изображение`,
    MEDIAGALLERY_DELETE_CONFIRM: `Вы уверены, что хотите удалить изображение?`,
    MEDIAGALLERY_DELETE_OK: 'Да, уверен!',
    MEDIAGALLERY_DELETE_REJECT: `Нет`,
    MEDIAGALLERY_TYPE_PDF: 'PDF',
    MEDIAGALLERY_SELECT_TYPE: 'Выберите тип',
    MEDIAGALLERY_FILL_SEARCH: 'Введите поисковый запрос',
    MEDIGALLERY_UPLOAD_TITLE: `Выбрать файлы`,
    MEDIAGALLERY_SINGLE_EDIT: 'Одиночное',
    MEDIAGALLERY_GROUP_EDIT: 'Групповое',
    MEDIGALLERY_CANNOT_GENERATE_PREVIEW: 'Невозможно показать превью',
    MEDIAGALLERY_UPLOAD_TITLE: 'Загрузка файлов',

    ADMIN_WIDGET_CODE: 'Кодовое название виджета',
    ADMIN_WIDGET_CODE_ERROR: 'Заполните кодовое название виджета',
    ADMIN_WIDGET_CODE_PLACEHOLDER: 'Не указан кодовое название виджета',

    BOLD_TEXT: 'Выделение текста',
    BOLD_TEXT_SELECTED: 'bold',

    /*спецпроект*/
    SPECIAL_PROJECT_TYPE: 'Тип спецпроекта',
    SELECT_SPECIAL_PROJECT_TYPE: 'Выберите тип спецпроекта',
    SPECIAL_MEDIA_COUNT: 'Количество медиа',
    POSITION: `Описание`,
    AUTHOR_DESCRIPTION_REQUIRED: 'Укажите описание автора',
    AUTHOR_DESCRIPTION: "Описание автора",
    AUTHOR_POSITION: 'Должность',
    AUTHOR_POSITION_REQUIRED: 'Заполните должность',
    AUTHOR_PHOTO_REQUIRED: 'Загрузите фотография автора',
    AUTHOR_EMAIL: 'Email автора',
    MEDIA_ADD_WATERMARK: 'Добавить watermark',

    /*афиша*/
    MAP_AFISHA: 'Афиша',
    AFISHA_LIST: 'Список документов',
    PUBLISHED_AFISHA_AT: 'Опубликована до',
    AFISHA_REDIRECT_TO: 'Ссылка с точки',
    AFISHA_REDIRECT_TO_ERROR: 'Укажите пожалуйста ссылку',

    /*округа*/
    REGION_MATERIALS: 'Материалы окр.',
    REGIONMAT_EDIT: 'Материал окр.',

    /*profiles*/
    PROFILE_DEVICES: 'Устройства пользователя',

    /*material gallery*/
    MATERIAL_GALLERY: 'Фотоподборки',
    MGALLERY_LIST: 'Фотоподборки',
    MGALLERY_EDIT: 'Редактирование',
    MGALLERY: 'Фотоподборка',
    INSERT_MGALLERY: 'Фотоподборка',
    MGALLERY_IMAGES:'Фотографии',

    /*CROSS_LINKS*/
    'CROSS_LINKS': 'Читайте также',

    /* STORIES */
    'STORIES': 'Сторис',
    'STORIES_LIST': 'Список сторис',
    'STORIES_EDIT': 'Редактирование',
    'STORIES_VIDEO': 'Видео',
    'UPLOAD_NEW_VIDEO': 'Новое видео',
    'UPLOAD_SEL_VIDEO': 'Архив',
    'STORIES_PREVIEW': 'Предпросмотр',
    'TYPE_STORIES': 'Сторис',


    /* media */
    'MEDIA.DELETE': 'Удалить',
    'MEDIA.EDIT': 'Редактировать',
    'DZEN_GROUP': 'Дзен',
    'DZEN_GREEN': 'Вечнозелёный',
    'DZEN_FULLTEXT': 'Полнотекст',
    'SELECT_TREND': "Выберите тему",

    'RECEIPT': 'Рецепты',
    'RECEIPT_LIST': 'Рецепты',
    'RECEIPT_EDIT': 'Рецепты',
    'TYPE_RECEIPT': 'Рецепты',
    'Receipt': 'Рецепты',
    'INGREDIENTS': 'Ингридиенты',
    'STT_LIST': 'Распознавание аудио',
    'VIDEO_STT': 'Видео для распознавания',
    'STT_RESULT': 'Результат распознавания',
    'STT_EDIT': 'Распознавание',
    'STT_PENDING': 'В очереди',
    'STT_PROCESS': 'Распознается',
    'STT_FINISHED': 'Обработан',
    'STT_ERROR': 'Ошибка',
    'TAGS_CHILDS': 'Связанные теги',
    'PUBLISH_AT_SBER': 'Сбер',
    'TEXT_TO_SPEECH': 'Синтез речи',
    'TTS_LIST': 'Синтез речи',
    'TTS_EDIT': 'Синтез речи',

    'STOPWORDS': 'Стоп-слова',
    'STOP_LIST': 'Стоп-слова',
    'STOP_EDIT': 'Стоп-слова',
    'STOP_WORD': 'Стоп-слово',
    'STOP WORD': 'Стоп-слово',
    'STOP_WORD_FILL': 'Укажите стоп-слово',

    'MEDIA_INFOGRAPHIC': 'Отображать как инфографику',
    'TYPE_GALLERY': 'Фотогалереи',
    'PREPUBLISHED_STATUS': 'Запланирован',
    'PREPUBLISHED_DASHBOARD': 'Запланирован',

    'MAP': 'Карта',

    'SPECIAL_BANNER': 'Настройки отображения',
    'SPECIAL_BANNER.TITLE_COLOR': 'Цвет заголовка',
    'SPECIAL_BANNER.GRADIENT_COLOR': 'Цвет градиента',
    'SPECIAL_BANNER.SUB_TITLE_COLOR': 'Цвет подзаголовка',
    'SPECIAL_BANNER.SUB_TITLE_COLOR_PLACEHOLDER': 'Выберите цвет подзаголовка',
    'SPECIAL_BANNER.TITLE_COLOR_PLACEHOLDER': 'Выберите цвет заголовка',

    'DATABASES': 'Списки',
    'INSTRUMENTS': 'Инструменты',
    'SCREENS': 'Скрины',

    'REDIRECTS': 'Редиректы',
    'REDIRECTS_LIST': 'Редиректы',
    'REDIRECT_FROM': 'Ссылка',
    'REDIRECTS_EDIT': 'Редактирование',
};

export default RU;
