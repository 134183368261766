import MediaSources from "../../media_sources";
import {parseUrlParams} from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";

export const vm = {
    SHOW_LANGS: true,
    APP_CLASS: 'vm',
    PROJECT_NAME: 'VM',
    PROJECT_PREFIX: 'vm',
    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    NEWSPAPER_TYPES: ['Утренний выпуск', 'Еженедельный выпуск', 'Вечерний выпуск', 'Новые Округа' ,'Дневной выпуск', 'Пресса в образовании', 'Москвичка', 'Спецвыпуск'],
    NEWSPAPER_REGIONS: ['Якиманка', 'Арбат', 'Замоскворечье', 'Тверской', 'Басманный', 'Пресненский', 'Таганский',
        'Хамовники', 'Красносельский', 'Мещанский', 'Орехово-Борисово Южное', 'Нагатинский затон',
        'Зябликово', 'Чертаново Центральное', 'Чертаново Северное', 'Донской',
        'Москворечье-Сабурово', 'Нагатино-Садовники', 'Нагорный', 'Даниловский', 'Братеево',
        'Орехово-Борисово Северное', 'Царицыно', 'Бирюлево Западное', 'Чертаново Южное',
        'Бирюлево Восточное', 'Капотня', 'Некрасовка', 'Рязанский', 'Кузьминки', 'Люблино',
        'Выхино-Жулебино', 'Марьино', 'Лефортово', 'Южнопортовый', 'Нижегородский', 'Печатники',
        'Текстильщики', 'Щербинка', 'Московский', 'Троицк', 'Троицкий АО', 'Новомосковский АО'],
    // settings for menu
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://files.vm.ru",
            VIDEO: "https://video.vm.ru/mp4",
            PDF: "https://files.vm.ru/pdf/",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be', `tvzvezda.ru`],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
            'tvzvezda.ru': (url) => {
                return (
                    `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            }
        },
        PREPARE: (path) => {
            if (path.indexOf('mp4/') + 1 > 0) return `https://video.vm.ru${path.replace('https://files.vm.ru', '')}`;
            if (path.indexOf('vecherka') + 1 === 0) return `https://files.vm.ru/vecherka${path}`;
            return path;
        },
    },
    WORKFLOW: {
        SHOW_SEARCH: true,
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            switch(item.type.toLowerCase()) {
                case "news":return `https://vm.ru/news/${item.publicId}-${item.slug}`;
                case "meaning":return `https://vm.ru/opinion/${item.publicId}-${item.slug}`;
                case "episode":return `https://vm.ru/tv/${item.publicId}-${item.slug}`;
                case "gallery":return `https://vm.ru/photo/${item.publicId}-${item.slug}`;
                case "infographic":return `https://vm.ru/infographic/${item.publicId}-${item.slug}`;
                case "battle":return `https://vm.ru/battle/${item.publicId}-${item.slug}`;
                case "show":return ``;
                case "newspaper":return ``;
                case "rnewspaper":return ``;
                case "schedule":return ``;
                case "author":return `https://vm.ru/author/${item.publicId}-${item.slug}`;
                case "trend":return `https://vm.ru/topic/${item.publicId}`;
                case "category":return `https://vm.ru/${item.slug}`;
                case "tag":return `https://vm.ru/tag/${item.slug}`;
                case "page":return `https://vm.ru/static/${item.slug}`;
                default: return `https://vm.ru/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
            }
        },
        STATUSES: ['DRAFT', 'READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT' : 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'DRAFT',
        LOCK_FOR: ['article','news', 'infographic', 'episode', 'gallery', 'meaning'],
        WORKFLOW_FOR: [
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
    },
    WIDGETS: {
        TYPES: ['Article', 'News', 'Episode', 'Infographic','Meaning', 'Trend', 'Banner', 'Show', 'Polls']
    },

    ELASTIC: {
        API: "https://admtest.vm.ru/elastic"
    },
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `https://admtest.vm.ru/api/backend`,
        ELASTIC_API: `https://admtest.vm.ru/elastic`,
    },

    UPLOAD: {
        "path": "https://admtest.vm.ru/api/backend/media"
    },
    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: `https://emauth.vmdaily.ru/`,
        // current project domain
        PROJECT_DOMAIN: `admtest.vm.ru`,
        // current project url path
        PROJECT_PATH: `https://admtest.vm.ru`,
        // path for oauth api
        API: `https://emauth.vmdaily.ru/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://emauth.vmdaily.ru/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },
    VIEWS: {
        documents: {
            "article": "default_view_vm",
            "author": "authors_view",
            "category": "category_view",
            "tag": "tag_view",
            "trend": "trend_view",
            "page": "page_view",
            "show": "show_view",
            "newspaper": "newspaper_view",
            "rnewspaper": "rnewspaper_view",
            "schedule": "schedule_view",
            "arates": 'arates_view',
            "aviews": 'aviews_view',
            "polls": 'poll',
            "mcat": 'anons_view',
            "covid": "covid"
        },
        documents_add: {
            "article": "add_article_vm",
            "trend": "add_trend",
            "news": "add_news",
            "author": "add_author",
            "category": "add_category",
            "tag": "add_tag",
            "banner": "add_banner",
            "page": "add_page",
            "newspaper": "add_newspaper",
            "rnewspaper": "add_rnewspaper",
            "episode": "add_episode",
            "battle": "add_battle",
            "schedule": "add_schedule",
            "arates": "arate_add",
            "aviews": "aviews_add",
            "infographic": "infographics_add",
            "polls": "poll_add",
            "mcat": "anons_add",
            "covid": "covid",
            "mgallery": "mgallery",
        }
    },
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',
        },
        {
            'title': 'DOCUMENTS',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    path: '/mediagallery',
                    component: 'media_gallery',
                }
            ],
            'icon': 'container',
            'submenu': [
                {
                    'title': 'NEWS',
                    'path': '/documents/news',
                    'icon': 'bars',
                },
                {
                    'title': 'Articles',
                    'path': '/documents/article',
                    'icon': 'bars',
                },
                {
                    'title': 'MEANING',
                    'path': '/documents/meaning',
                    'icon': 'audit',
                },
                {
                    'title': 'Episode',
                    'path': '/documents/episode',
                    'icon': 'video-camera',
                },
                {
                    'title': 'MATERIAL_GALLERY',
                    'path': '/documents/mgallery',
                    'icon': 'appstore',
                },
                {
                    'title': 'Gallery',
                    'path': '/documents/gallery',
                    'icon': 'appstore',
                },
                {
                    'title': 'Infographic',
                    'path': '/documents/infographic',
                    'icon': 'picture',
                },

                {
                    'title': 'SHOW',
                    'path': '/documents/show',
                    'icon': 'youtube',
                },
                {
                    'title': 'OPINION_BATTLE',
                    'path': '/documents/battle',
                    'icon': 'team',
                },
                {
                    'title': 'ARCHIVE',
                    'path': '/archive',
                    'icon': 'hdd',
                },
                {
                    'title': 'EMBARGO',
                    'path': '/documents/embargo',
                    'icon': 'exclamation-circle',
                },
                {
                    'title': 'ARCHIVE_NEWSPAPER',
                    'path': '/documents/newspaper',
                    'icon': 'file-pdf',
                },
                {
                    'title': 'REGION_NEWSPAPER',
                    'path': '/documents/rnewspaper',
                    'icon': 'file-pdf',
                },
                {
                    'title': 'SCHEDULE',
                    'path': '/documents/schedule',
                    'icon': 'schedule',
                },
                {
                    'title': 'MAP_CATEGORY',
                    'path': '/documents/mcat',
                    'icon': 'folder',
                },
                {
                    title: 'MEDIA_GALLERY_LIST',
                    path: '/mediagallery',
                    icon: 'picture',
                }
            ]
        },
        {
            'title': 'PUSH',
            'path': '/push',
            'icon': 'notification',
            'component': 'push',
        },
        {
            'title': 'REPORTS',
            "path": "/reports",
            "icon": "area-chart",
            'subroutes': [
                {
                    'path': '/reports/budget/:id',
                    'component': 'view_budget',
                },
            ],
            'submenu': [
                {
                    'title': 'Yandex',
                    "path": "/reports/yandex",
                    "icon": "table",
                    "component": "yandex_report",
                },
                {
                    'title': 'Material_stats',
                    "path": "/reports/materials_stats",
                    "icon": "line-chart",
                    "component": "material_report",
                },
                {
                    'title': 'Widgets_stats',
                    "path": "/reports/widgets",
                    "icon": "area-chart",
                    "component": "widget_report",
                },
                {
                    'title': 'Budget',
                    "path": "/reports/budget",
                    "icon": "table",
                    "component": "budget",
                },
                {
                    'title': 'STREAM_STATS',
                    "path": "/reports/stream",
                    "icon": "table",
                    "component": "stream_report",
                },
                {
                    'title': 'Video',
                    "path": "/reports/video",
                    "icon": "table",
                    "component": "video_report",
                },
                {
                    'title': 'Video_VIEWS',
                    "path": "/reports/video_views",
                    "icon": "table",
                    "component": "viqeo_report",
                },
            ]
        },
        {
            'title': 'SPECIAL_PROJECTS',
            'icon': 'folder',
            'submenu': [
                {
                    'title': 'COVID19',
                    'icon': 'table',
                    'path': '/documents/covid',
                },
            ]
        },
        {
            'title': 'AUTHORS',
            'icon': 'team',
            'submenu': [
                {
                    'title': 'AUTHORS_LIST',
                    'icon': 'team',
                    'path': '/documents/author',
                },
                {
                    'title': 'AUTHORS_RATES',
                    'icon': 'team',
                    'path': '/documents/arates',
                },
                {
                    'title': 'AUTHORS_VIEWS',
                    'icon': 'team',
                    'path': '/documents/aviews',
                }
            ]
            // 'component': 'documents_authors',
        },
        {
            'title': 'CATEGORIES',
            'path': '/documents/category',
            'icon': 'folder',
            // 'component': 'documents_category',
        },
        {
            'title': 'TAGS',
            'path': '/documents/tag',
            'icon': 'tag',
            // 'component': 'documents_tags',
        },
        {
            'title': 'COMMENTS',
            'path': '/comments',
            'icon': 'message',
            'component': 'comments'
        },
        {
            'title': 'POLLS',
            'path': '/documents/polls',
            'icon': 'experiment',
        },
        {
            'title': 'CHAT',
            'path': '/chat',
            'icon': 'message',
            'component': 'chat'
        },
        {
            'title': 'PROFILES',
            'path': '/profiles',
            'icon': 'usergroup-add',
            'subroutes': [
                {
                    'path': '/profiles/:id',
                    'component': 'profile_edit',
                },
            ],
            'submenu': [
                {
                    'title': 'PROFILES_LIST',
                    'path': '/profiles',
                    'icon': 'user',
                    'component': 'profile_index',
                },
                {
                    'title': 'PROFILES_GROUP',
                    'path': '/profiles/groups',
                    'icon': 'hdd',
                    'component': 'groups_index_profile',
                },
                {
                    'title': 'PROFILES_PERMISSIONS',
                    'path': '/profiles/permissions',
                    'icon': 'setting',
                    'component': 'permissions_index_profile',
                },
            ]
        },
        {
            'title': 'TRENDS',
            'path': '/documents/trend',
            'icon': 'video-camera',
        },
        {
            'title': 'MEDIA',
            'path': '/media',
            'icon': 'picture',
            'component': 'media',
        },

        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'layout',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'submenu': [
                {
                    'title': 'WIDGETS_EDITOR',
                    'path': '/widgets',
                    'icon': 'appstore',
                    'component': 'widget',
                },
                {
                    'title': 'WIDGETS_ADS',
                    'path': '/widgets_ads',
                    'icon': 'appstore',
                    'component': 'widget',
                },
            ],
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },

            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },
        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        }
    ],
};

export default vm;
