import React from 'react';
import {Layout} from 'antd';
import asyncComponent from './../core/components/async';
import Config from './../config';
import { AppConsumer } from './../context/app';


const getUserPermissions = (p) => {
    if (p && p.info) {
        if (p.info.permissions && Array.isArray(p.info.permissions)) {
            return p.info.permissions;
        }
    }
    return false;
}

const isPhotoUser = (p) => {
    const perm = getUserPermissions(p);
    return perm && Array.isArray(perm) && perm.indexOf(`ONLY_PHOTO`) + 1 > 0;
}

const canDropImages = (p) => {
    const perm = getUserPermissions(p);
    return perm && Array.isArray(perm) && perm.indexOf(`DROP_IMAGES`) + 1 > 0;
}

const isSTTUser = (p) => {
    const perm = getUserPermissions(p);
    return perm && Array.isArray(perm) && perm.indexOf(`ONLY_STT`) + 1 > 0;
}


const Menu = asyncComponent(() => {
    return import(`./../core/components/menu.js`);
});

const Header = asyncComponent(() => {
    return import(`./../core/components/header/index.js`);
});

const Router = asyncComponent(() => {
    return import('./../core/router/index.js');
});


export class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    componentDidMount() {
        const isSTT = isSTTUser(this.props.user || {});
        if (isSTT && window.location.pathname.indexOf('/documents/stt') + 1 === 0) {
            window.location.href = '/documents/stt';
        }
    }

    render() {
        const isPhoto = isPhotoUser(this.props.user || {});
        const isCanDrop = canDropImages(this.props.user || {});
        const isSTT = isSTTUser(this.props.user || {});
        
        let menu = isPhoto ? Config.PHOTO_MENU : Config.MENU;
        if (isSTT) {
            menu = Config.STT_MENU;
        }

       
        return (
            <Layout style={{minHeight: '100vh'}}>

                    <Layout.Sider
                        collapsible
                        className={`sidebar`}
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <div className={`logo`}/>
                        <Menu items={menu || []} />
                    </Layout.Sider>
                <Layout>
                    <Layout.Header className={`app_header`} style={{paddingLeft: isPhoto || isSTT ? '20px' : '0'}}>
                        <Header consumer={AppConsumer}/>
                    </Layout.Header>
                    <Router {...this.props} isPhotoUser={isPhoto} isSTTUser={isSTT} isCanDropImages={isCanDrop}/>
                </Layout>
            </Layout>
        );
    }
}

export default DefaultLayout;
