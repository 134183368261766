import MediaSources from "../../media_sources";
import {parseUrlParams} from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";



export const rbth_en_test = {
    SHOW_LANGS: true,
    APP_CLASS: 'rbth',
    PROJECT_NAME: 'RBTH',
    LOCALE: "ru",
    PREVIEW_DOMAIN: 'https://gatewaytorussia.ru',
    PREVIEW_HASH: "AE30845FBF6EE09305710B753A9C264C",
    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],
    UPLOAD: {
        "path": "http://localhost:8086/api/backend/media"
    },
    // settings for documents templates
    VIEWS: {
        documents: {
            "article": "default",
            "author": "authors_view",
            "category": "category_view",
            "tag": "tag_view",
            "book_genre": "tag_view",
            "films_genre": "tag_view",
            "music_genre": "tag_view",
            "theater_type": "tag_view",
            "trend": "trend_view",
            "page": "page_view",
            "book_authors": 'books_authors_view',
            "films_authors": 'books_authors_view',
            "music_authors": 'books_authors_view'
        },
        documents_add: {
            "article": "add_default",
            "trend": "add_trend",
            "news": "add_news",
            "author": "add_author",
            "category": "add_category",
            "tag": "add_tag",
            "book_genre": "add_genre",
            "films_genre": "add_genre",
            "music_genre": "add_genre",
            "theater_type": "add_genre",
            "banner": "add_banner",
            "page": "add_page",
            "events": "add_events",
            "services": "add_services",
            "places": "add_places",
            "films": "add_films",
            "music": "add_music",
            "books": "add_book",
            "theater": "add_theater",
            "routes": "add_routes",
            "book_authors": "add_author",
            "films_authors": "add_author",
            "music_authors": "add_author",
        }
    },
    // settings for menu
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',
        },
        {
            'title': 'DOCUMENTS',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                }
            ],
            'icon': 'container',
            'submenu': [
                {
                    'title': 'Articles',
                    'path': '/documents/article',
                    'icon': 'bars',
                },
                {
                    'title': 'Videos',
                    'path': '/documents/videos',
                    'icon': 'video-camera',
                },
                {
                    'title': 'Gallery',
                    'path': '/documents/gallery',
                    'icon': 'appstore',
                },
                {
                    'title': 'Events',
                    'path': '/documents/events',
                    'icon': 'calendar',
                },
                {
                    'title': 'Services',
                    'path': '/documents/services',
                    'icon': 'setting',
                },
                {
                    'title': 'Places',
                    'path': '/documents/places',
                    'icon': 'environment',
                },
                {
                    'title': 'Routes',
                    'path': '/documents/routes',
                    'icon': 'flag',
                },
            ]
        },
        {
            'title': 'Books',
            'path': '/documents/books',
            'icon': 'book',
            'submenu': [
                {
                    'title': 'LIST_BOOK',
                    'path': '/documents/books',
                    'icon': 'book',
                },
                {
                    'title': 'BOOKS_AUTHORS',
                    'path': '/documents/book_authors',
                    'icon': 'team',
                },
                {
                    'title': 'BOOKS_GENRE',
                    'path': '/documents/book_genre',
                    'icon': 'database',
                }
            ]
        },
        {
            'title': 'Theater',
            'path': '/documents/theater',
            'icon': 'tags',
            'submenu': [
                {
                    'title': 'LIST_THEATER',
                    'path': '/documents/theater',
                    'icon': 'tags',
                },
                {
                    'title': 'THEATER_TYPE',
                    'path': '/documents/theater_type',
                    'icon': 'database',
                }
            ]
        },
        {
            'title': 'Films',
            'path': '/documents/films',
            'icon': 'youtube',
            'submenu': [
                {
                    'title': 'FILMS_LIST',
                    'path': '/documents/films',
                    'icon': 'youtube',
                },
                {
                    'title': 'FILMS_AUTHORS',
                    'path': '/documents/films_authors',
                    'icon': 'team',
                },
                {
                    'title': 'FILMS_GENRE',
                    'path': '/documents/films_genre',
                    'icon': 'database',
                }
            ]
        },
        {
            'title': 'Music',
            'path': '/documents/music',
            'icon': 'customer-service',
            'submenu': [
                {
                    'title': 'MUSIC_LIST',
                    'path': '/documents/music',
                    'icon': 'customer-service',
                },
                {
                    'title': 'MUSIC_AUTHORS',
                    'path': '/documents/music_authors',
                    'icon': 'team',
                },
                {
                    'title': 'MUSIC_GENRE',
                    'path': '/documents/music_genre',
                    'icon': 'database',
                }
            ]
        },
        {
            'title': 'AUTHORS',
            'path': '/documents/author',
            'icon': 'team',
            // 'component': 'documents_authors',
        },
        {
            'title': 'CATEGORIES',
            'path': '/documents/category',
            'icon': 'folder',
            // 'component': 'documents_category',
        },
        {
            'title': 'TAGS',
            'path': '/documents/tag',
            'icon': 'tag',
            // 'component': 'documents_tags',
        },
        {
            'title': 'TRENDS',
            'path': '/documents/trend',
            'icon': 'video-camera',
        },
        {
            'title': 'MEDIA',
            'path': '/media',
            'icon': 'picture',
            'component': 'media',
        },

        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'layout',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },
            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },
        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        }
    ],
    // oauth configuration
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `http://localhost:8086/api/backend`,
    },

    ELASTIC: {
        API: "/elastic"
    },


    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "http://localhost:8086",
            VIDEO: "http://localhost:8086",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
    },

    WIDGETS: {
        TYPES: ['Article', 'Events', 'Services', 'Places', "Films", "Books", "Music", "Routes", "Videos", "Book_genre", "Films_genre", "Music_genre", "Theater", "Theater_type"]
    },

};

export default rbth_en_test;
